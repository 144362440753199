import {Controller} from 'stimulus';
import Pikaday from 'pikaday';
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'arrive',
    'depart',
    'toggle',
    'submit',
  ];

  get formElement() {
    return document.querySelector('.js-bookingWidgetForm');
  }

  get bookingWidgetModal() {
    return document.querySelector('.js-bookingWidgetModal');
  }

  get dateArriveField() {
    return document.querySelector('.booking__widget .form__date--arrive');
  }

  get dateDepartField() {
    return document.querySelector('.booking__widget .form__date--depart');
  }

  get mobileBookingWidgetToggles() {
    return document.querySelectorAll('.js-bookingWidgetMobileToggle');
  }

  connect() {
    this.pickerArrive = null;
    this.pickerDepart = null;

    this.initCalendars();
    this.addEventListeners();
  }

  /**
   * Add event listeners to the booking widget
   */
  addEventListeners() {
    this.formElement.addEventListener('submit', this.submitForm.bind(this));

    document.addEventListener('click', (event) => {
      // If the click is outside the booking widget, close the calendar
      if (!event.target.closest('.booking__widget, .booking__toggle')) {
        this.handleClickOutsideModal();
      }
    });

    if (!this.mobileBookingWidgetToggles && !this.mobileBookingWidgetToggles.length) return;
    this.mobileBookingWidgetToggles.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        this.handleToggleBookingWidget();
      });
    });
  }

  /**
   * Handle clicks on the book now button
   */
  handleBookNow() {
    const submitEvent = new Event('submit', { 'bubbles': true, 'cancelable': true });
    this.formElement.dispatchEvent(submitEvent);
  }

  /**
   * Handle clicks outside the booking widget
   */
  handleClickOutsideModal() {
    if (!this.bookingWidgetModal) return;
    // If the booking widget is not open we don't do anything otherwise it
    // will prevent other modals from opening
    if (!this.bookingWidgetModal.classList.contains('booking__widget--fixed')) return;
    this.handleToggleBookingWidget('hide');
  }

  /**
   * Handle clicks on the booking widget toggle
   * @param {string} action - either 'hide' or 'toggle' (defaults to toggling if unspecified)
   */
  handleToggleBookingWidget(action = 'toggle') {
    if (!this.bookingWidgetModal) return;
    const isCurrentlyOpen = document.body.classList.contains('-is-open-modal');

    // Determine the action to take based on the current state or passed action.
    let shouldOpen = (action === 'hide') ? false : !isCurrentlyOpen;

    // Apply changes to the toggle
    this.bookingWidgetModal.classList.toggle('is-active', shouldOpen);
    this.bookingWidgetModal.classList.toggle('booking__widget--fixed', shouldOpen);
    document.body.classList.toggle('-is-open-modal', shouldOpen);

    // Update aria-expanded attribute on the toggle
    this.toggleTarget.setAttribute('aria-expanded', shouldOpen ? "true" : "false");
  }

  /**
   * Submit the form
   * - We need to update the hidden inputs with the selected dates
   * @param e
   */
  submitForm(e) {
    if (e.currentTarget !== this.formElement) return;
    // iterate over the date fields
    e.currentTarget.querySelectorAll('.form__date').forEach(dateField => {
      let date;
      // assign the values to the hidden inputs, based on the calendars
      if (dateField.getAttribute('name') === 'arrive') {
        date = this.pickerArrive._o.startRange;
      } else if (dateField.getAttribute('name') === 'depart') {
        date = this.pickerDepart._o.endRange;
      }
      dateField.value = moment(date).format('YYYY/MM/DD');
    });
    // now that the hidden inputs are updated, submit the form
    this.formElement.submit();
  }

  /**
   * Initialize the calendars
   * - Attach the PikaDay calendars to the DOM
   */
  initCalendars() {
    const defaultStartDate = new Date();
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 1);

    // create the default calendar options
    const calendarOptions = {
      minDate: defaultStartDate,
      startRange: defaultStartDate,
      endRange: defaultEndDate,
      format: 'MMM DD',
      bound: false,
      numberOfMonths: 2,
      keyboardInput: true,
      container: this.formElement.querySelector('.form__fieldset'),
      i18n: {
        previousMonth: 'Previous Month',
        nextMonth: 'Next Month',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
    };

    this.initializePikaday('arriving', calendarOptions);
    this.initializePikaday('departing', calendarOptions);

    this.updateStartDate(defaultStartDate);
    this.updateEndDate(defaultEndDate);

    // make sure end range has correct class to start
    this.pickerArrive.el.querySelector('.is-inrange').classList.add('is-endrange');

    // hide the departing calendar
    this.pickerDepart.hide();
  }

  /**
   * Initialize the Pikaday calendars based on the passed parameters
   * @param action
   * @param calendarOptions
   */
  initializePikaday(action, calendarOptions) {
    if (action !== 'arriving' && action !== 'departing') {
      console.error("Unsupported action:", action);
      return;
    }
    let target, picker, updateFn, ariaLabel, other;
    if (action === 'arriving') {
      target = this.dateArriveField;
      picker = 'pickerArrive';
      other = 'pickerDepart';
      updateFn = this.updateStartDate;
      ariaLabel = 'Arriving';
    } else if (action === 'departing') {
      target = this.dateDepartField;
      picker = 'pickerDepart';
      other = 'pickerArrive';
      updateFn = this.updateEndDate;
      ariaLabel = 'Departing';
    }

    let options = {
      ...calendarOptions,
      field: target,
      onSelect: () => {
        const date = this[picker].getDate();
        updateFn.call(this, date);
      },
      onDraw: () => {
        // keep the calendars in sync
        if (!this[picker]) return;
        let mo = this[picker].calendars[0].month;
        let otherMo = this[other].calendars[0].month;
        let yr = this[picker].calendars[0].year;
        let otherYr = this[other].calendars[0].year;
        if (mo !== otherMo) this[other].gotoMonth(mo);
        if (yr !== otherYr) this[other].gotoYear(yr);
      },
      onOpen: () => {
        this.addAriaLabel(ariaLabel);
      },
    };

    this[picker] = new Pikaday(options);
  }

  /**
   * Update the start date
   * @param startDate
   */
  updateStartDate(startDate) {
    this.pickerArrive.setStartRange(startDate);
    this.pickerDepart.setStartRange(startDate);
    this.pickerArrive.setEndRange(startDate);
    this.pickerDepart.setEndRange(startDate);
    this.pickerDepart.setMinDate(startDate);
    this.pickerArrive.hide();
    this.pickerDepart.show();
    this.updateArrive(startDate);
  }

  /**
   * Update the end date
   * @param endDate
   */
  updateEndDate(endDate) {
    this.pickerDepart.setEndRange(endDate);
    this.pickerArrive.setEndRange(endDate);
    this.pickerArrive.show();
    this.pickerDepart.hide();
    this.updateDepart(endDate);
  }

  /**
   * Update the arrival date
   * @param date
   */
  updateArrive(date) {
    const arrive = this.arriveTarget;
    arrive.querySelector('.month').textContent = moment(date).format('MMM');
    arrive.querySelector('.day').textContent = moment(date).format('DD');
  }

  /**
   * Update the departure date
   * @param date
   */
  updateDepart(date) {
    const depart = this.departTarget;
    depart.querySelector('.month').textContent = moment(date).format('MMM');
    depart.querySelector('.day').textContent = moment(date).format('DD');
  }

  /**
   * Add aria-label to the calendar selects
   * - TODO : is there a better way to handle this ?
   *   - this was from the CoffeeScript version
   * @param copy
   */
  addAriaLabel(copy) {
    document.querySelector('.pika-select-month:not([aria-label])')
            .setAttribute('aria-label', `Select ${copy} Month`);
    document.querySelector('.pika-select-year:not([aria-label])')
            .setAttribute('aria-label', `Select ${copy} Year`);
  }
}
