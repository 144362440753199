import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    if (!document.body.classList.contains('body__generic--details')) {
      window.addEventListener('scroll', throttle(this.pagescroll, 250));
    }
  }

  toggle() {
    const navToggle = document.querySelector('.js-nav-toggle');
    navToggle.classList.toggle('site__nav--toggle-checked');
    const expanded = navToggle.getAttribute('aria-expanded');
    navToggle.setAttribute('aria-expanded', !expanded);
    this.toggle_body_locked();
  }

  toggle_body_locked() {
    const body = document.body;
    body.classList.toggle('-body-locked');
    if (body.classList.contains('-body-locked')) {
      body.classList.remove('-page-scrolling');
    } else {
      this.pagescroll();
    }
  }

  pagescroll() {
    if (document.body.classList.contains('notransition')) {
      return;
    }
    const body = document.body;
    const header = document.querySelector('.site__header');
    const footer = document.querySelector('.site-footer');
    const viewport = {
      top: window.scrollY,
      bot: window.scrollY + window.innerHeight,
    };

    // Page has started scrolling
    const isScrolling = viewport.top > 20;
    body.classList.toggle('-page-scrolling', isScrolling);
    header.classList.toggle('-page-scrolling', isScrolling);

    // Page is at the bottom
    const nearBottom = viewport.bot >= footer.offsetTop;
    body.classList.toggle('-page-bottom', nearBottom);
  }
}
