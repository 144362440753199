import { Controller } from 'stimulus'
import { handleSubmitForm } from 'shared/assets/javascript/contact_form_7'

export default class extends Controller {

  connect() {
    this.eventVenue = document.querySelector('.js-event-venue')
    this.formVenue = document.querySelector('.js-form-venue')
    if (this.formVenue && this.eventVenue) {
      this.formVenue.value = this.eventVenue.value
    }
    this.container = document.querySelector('.js-eventInquiryModal')
    this.submitBtn = this.container.querySelector('.js-submit-form')
    this.formTarget = this.container.querySelector('form')
    this.successContainer = this.container.querySelector('.js-success-container')
    this.closeBtn = document.querySelector('.js-eventInquiryModal .js-modal-close')
    this.toggle = document.querySelectorAll('.js-events-inquiry-toggle')
    this.inquiryModal = document.querySelector('.js-eventInquiryModal')
    this.toggle.forEach((el) => {
      el.addEventListener('click', this.openForm.bind(this))
    })
    this.closeBtn.addEventListener('click', this.closeForm.bind(this))
    if (this.submitBtn) {
      this.submitBtn.addEventListener('click', this.submitForm.bind(this))
    }
  }

  openForm(e) {
    e.preventDefault()
    this.inquiryModal.classList.add('active')
    document.body.classList.add('-is-open-modal')
  }

  closeForm(e) {
    e.preventDefault()
    this.inquiryModal.classList.remove('active')
    document.body.classList.remove('-is-open-modal')
  }

  async submitForm(e){
    e.preventDefault()
    let response = await handleSubmitForm(this.formTarget)
    if (!response) return
    if (response.status === 'mail_sent') {
      this.successContainer.classList.remove('-hidden')
      this.successContainer.classList.add('-show')
      this.formTarget.classList.add('-hide')
    } else {
      console.log('error')
      console.log({response})
    }
  }

}
